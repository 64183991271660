import React, { Suspense, lazy } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import NoPermission from '../../components/NoPermission'
import { RootState } from '../../setup'
import { FallbackView } from '../../_metronic/partials'
import { UserModel } from '../modules/auth/models/UserModel'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const userCan = {
    administratorList: user.permissions.includes('Administrator List'),
    administratorView: user.permissions.includes('Administrator View'),
    roleList: user.permissions.includes('Role List'),
    roleView: user.permissions.includes('Role View'),
    menuList: user.permissions.includes('Menu List'),
    permissionList: user.permissions.includes('Permission List'),
    venderList: user.permissions.includes('Vender List'),
    serviceList: user.permissions.includes('Service List'),
    bill_cycleList: user.permissions.includes('Bill Cycle List'),
    tran_modeList: user.permissions.includes('Tran Mode List'),
    service_typeList: user.permissions.includes('Service Type List'),
    industry_typeList: user.permissions.includes('Industry Type List'),
    customer_sourceList: user.permissions.includes('Customer Source List'),
    customer_typeList: user.permissions.includes('Customer Type List'),
    provinceList: user.permissions.includes('Province List'),
    districtList: user.permissions.includes('District List'),
    agentList: user.permissions.includes('Agent List'),
    agentView: user.permissions.includes('Agent View'),
    popsList: user.permissions.includes('POP List'),
    invoice_templateList: user.permissions.includes('Invoice Template List'),
    contactList: user.permissions.includes('Contact List'),
    customerList: user.permissions.includes('Customer List'),
    customerView: user.permissions.includes('Customer View'),
    customerSetUpList: user.permissions.includes('Customer SetUp'),
    contactSetUpList: user.permissions.includes('Contact SetUp'),
    subscriptionSetUpList: user.permissions.includes('Subscription SetUp'),
    tariffList: user.permissions.includes('Tariff Internet List'),
    invoiceStatusList: user.permissions.includes('Invoice Status List'),
    venderServiceList: user.permissions.includes('Vender Service List'),
    billItemList: user.permissions.includes('Bill Item List'),
    paymentOptionList: user.permissions.includes('Payment Option List'),
    paymentStatusList: user.permissions.includes('Payment Status List'),
    emailTemplateList: user.permissions.includes('Email Template List'),
    currencyList: user.permissions.includes('Currency List'),
    runbilloperationList: user.permissions.includes('Run Bill Operation List'),
    invoicesList: user.permissions.includes('Invoices List'),
    internalReport: user.permissions.includes('Internal Report List'),
    externalReport: user.permissions.includes('External Report List'),
    searchInvoiceList: user.permissions.includes('Search Invoice List'),
    unpaidInvoiceList: user.permissions.includes('Unpaid invoice List'),
    transactionLogList: user.permissions.includes('Transaction Log List'),
    customerBalanceList: user.permissions.includes('Customer Balance List'),
    customerReportList: user.permissions.includes('Customer Report List'),
    receiptReportList: user.permissions.includes('Receipt Report List'),
    subscriptionReportList: user.permissions.includes('Subscription Report List'),
    invoiceReportList: user.permissions.includes('Invoice Report List'),
    customerSubscriptionReportList: user.permissions.includes('Customer Subscription Report List'),
    subscriptionServiceReportList: user.permissions.includes('Subscription Service Report List'),
    invoiceSummaryList: user.permissions.includes('Invoice Summary List'),
    broadCastEmailList: user.permissions.includes('BroadCast Email List'),
    searchCustomerList: user.permissions.includes('Search Customer List'),
    paymentMonitorList: user.permissions.includes('To Be Renew List'),
    subscriptionsList: user.permissions.includes('Subscriptions List'),
    devicesList: user.permissions.includes('Devices List'),
    devicesDetailList: user.permissions.includes('Devices Detail'),
    venderContractList: user.permissions.includes('Vender Contract List'),
    subscriptionHistoryList: user.permissions.includes('Subscription History List'),
    networkingList: user.permissions.includes('Subscription NetWorkingSetting'),
    interfaceList: user.permissions.includes('Interface List'),
    vlanList: user.permissions.includes('VLAN List'),
    serviceItemListShow: user.permissions.includes('Service Item List Show'),
    unpaidReportList: user.permissions.includes('Unpaid Report List'),
    invoiceSummaryHistoryList: user.permissions.includes('Invoice Summary History List'),
    guestOwnerOnly: user.permissions.includes('Guest Owner Only'),
  }

  const AccountPage = lazy(() => import('../pages/account/AccountPage'))

  //!NOT YET
  const Tariff = lazy(() => import('../pages/tariff/Tariff'))

  const Agent = lazy(() => import('../pages/agent/Agent'))
  const AgentDetail = lazy(() => import('../pages/agent/AgentDetail'))

  const CustomerTable = lazy(() => import('../pages/customer/CustomerTable'))

  const CustomerSetUp = lazy(() => import('../pages/customer/CustomerSetUp'))
  const ContactSetUp = lazy(() => import('../pages/customer/Contact'))
  const SubscriptionSetUp = lazy(() => import('../pages/customer/Subscription'))
  const UserInfo = lazy(() => import('../pages/customer/detail/UserInfo'))

  const RouterDevice = lazy(() => import('../pages/router-device/RouterDevice'))
  const RouterDeviceDetail = lazy(() => import('../pages/router-device/RouterDeviceDetail'))
  const VenderContract = lazy(() => import('../pages/vender-contract/VenderContract'))

  const Vlan = lazy(() => import('../pages/vlan/Vlan'))
  const Interface = lazy(() => import('../pages/interface/Interface'))



  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />

        {/* Account user */}
        <Route path='/account' component={AccountPage} />

        {/* Tariff */}
        <Route path='/tariff' render={() => userCan.tariffList ? <Tariff /> : <NoPermission />} />

        {/* Agent */}
        <Route path='/agent/:id/detail' render={({ match }) => userCan.agentView ?
          <AgentDetail match={match} /> :
          <NoPermission />
        } />
        <Route path='/agent' render={() => userCan.agentList ? <Agent /> : <NoPermission />} />

        {/* Customer */}
        <Route path='/customer/:id/:agentid/detail' render={({ match }) => userCan.customerView ?
          <UserInfo match={match} />
          :
          <NoPermission />
        } />

        <Route path='/customer' render={(match) =>
          userCan.customerList ?
            userCan.guestOwnerOnly ?
              <Redirect to={`/customer/${user.customer_id}/${user.agent_id}/detail/general`} />
              :
              <CustomerTable match={match} />
            :
            <NoPermission />
        } />

        {/* Customer SetUp */}
        <Route path='/setup-customer' render={() => userCan.customerSetUpList ? <CustomerSetUp /> : <NoPermission />} />
        {/* Contact SetUp */}
        <Route path='/contact-setup/:id/:agentid' render={({ match }) => userCan.contactSetUpList ?
          <ContactSetUp match={match} /> :
          <NoPermission />
        } />
        {/* Subscription SetUp */}
        <Route path='/subscription-setup/:id/:agentid' render={({ match }) => userCan.subscriptionSetUpList ?
          <SubscriptionSetUp match={match} /> :
          <NoPermission />
        } />


        {/* Devices */}
        <Route path='/device/:id/detail' render={({ match }) => userCan.devicesDetailList ?
          <RouterDeviceDetail match={match} />
          :
          <NoPermission />
        } />
        <Route path='/device' render={() => userCan.devicesList ? <RouterDevice /> : <NoPermission />} />

        {/* Vender Contract */}
        <Route path='/vender-contract' render={() => userCan.venderContractList ? <VenderContract /> : <NoPermission />} />

        {/* Interface */}
        <Route path='/interface' render={() => userCan.interfaceList ? <Interface /> : <NoPermission />} />
        {/* Vlan */}
        <Route path='/vlan' render={() => userCan.vlanList ? <Vlan /> : <NoPermission />} />


        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
