/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageTitle } from "../../../_metronic/layout/core";
import { Toolbar1 } from "../../../_metronic/layout/components/toolbar/Toolbar1";
import { shallowEqual, useSelector } from "react-redux";
import { UserModel } from "../../modules/auth/models/UserModel";
import { RootState } from "../../../setup";
import { FC } from "react";
import { DashboardCount } from "./components/DashboardCount";
import { SubscriptionDash } from "./components/SubscriptionDash";
import { NewDash } from "./components/NewDash";

const DashboardWrapper: FC = () => {
  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userCan = {
    view: user.permissions.includes(`Dashboard View`),
    technical: user.permissions.includes(`Dashboard Tenical`),
    finance: user.permissions.includes(`Dashboard Finance`),
    invoice: user.permissions.includes(`Dashboard Invoice`),
    subscription: user.permissions.includes(`Dashboard Subscription`),
    income: user.permissions.includes(`Dashboard Income`),
    subscriptionList: user.permissions.includes(`Dashboard Subscription List`),
  };

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <Toolbar1 />

      <div className='row gy-5'>
        <div className="col-xl-4">
          <DashboardCount
            className="card card-stretch-50"
            color="primary"
            title="Unpaid Invoice"
            subtitle="Tolal Unpaid Invoices"
            icon="/media/icons/duotune/files/fil008.svg"
            status={1}
            api={`/invoice/unpaid-invoice-bycustomer`}
            url={`/customer/${user.customer_id}/${user.agent_id}/detail/finance/finance-invoice`}
          />
          <DashboardCount
            className="card card-stretch-50"
            color="danger"
            title="Unpaid Invoice"
            subtitle="Tolal Unpaid Invoices"
            icon="/media/icons/duotune/files/fil007.svg"
            status={0}
            api={`/invoice/unpaid-invoice-bycustomer`}
            url={`/customer/${user.customer_id}/${user.agent_id}/detail/finance/finance-invoice`}
          />
        </div>
        <div className='col-xl-4 col-md-12'>
          <NewDash
            className='card-stretch'
          />
        </div>
        <div className='col-xl-4 col-md-12'>
          <SubscriptionDash className='card-stretch' />
        </div>

      </div>
    </div>
  );
};

export { DashboardWrapper };
