/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { DataContext } from '../../../reducer/GlobalState'
import axios from 'axios'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'
import TobeRenewModal from './custom/TobeRenewModal'


type CusProp = {
  color: string
  title: string
  subtitle: string
  icon: string
  status: number
}
const ToBeNew: React.FC<CusProp> = ({ color, title, subtitle, icon, status }) => {

  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
  const { dispatch } = useContext(DataContext)
  const [loading, setLoading] = useState(false)

  const [counts, setCounts] = useState()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {

    setLoading(true)
    axios
      .get(`/subscription/number-subscription-toberenew-bycustomer/${user.customer_id}`)
      .then((resp) => {
        setCounts(resp.data.count)
        const delayDebounceFn = setTimeout(() => {
          setLoading(false)
        }, 500)
        return () => clearTimeout(delayDebounceFn)
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
  }, [dispatch, status, user])
  return (
    <div className={`bg-${color} px-6 py-8 rounded-2 d-flex justify-content-between align-items-center`}>
      <div>
        <KTSVG
          path={icon}
          className={`svg-icon-3x svg-icon-white d-block my-2`}
        />
        <div onClick={()=>setShowModal(true)} className={`text-white fw-bold fs-6 cursor-pointer`}>
          {title}
        </div>
      </div>
      <span className={`fw-bolder fs-1 mb-2 text-white`}>
        {loading ?
          <span className={`spinner-border text-white`}></span>
          :
          <span className={`fw-bolder mb-2 text-white`}>{counts}</span>
        }
      </span>
      <TobeRenewModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

type Props = {
  className: string
}

const NewDash: React.FC<Props> = ({ className }) => {


  return (
    <div className={`card ${className}`}>
      <div className={`card-header border-0`}>
        <h3 className='card-title fw-bolder text-dark'>Customer</h3>
      </div>
      <div className='card-body pt-0'>
        <ToBeNew
          color='danger'
          title='To Be Renew'
          subtitle='Total active subscription'
          icon='/media/icons/duotune/arrows/arr058.svg'
          status={1}
        />
      </div>
      
    </div>
  )
}

export { NewDash }
