/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../../../reducer/GlobalState'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'

type SubProps = {
  color: string
  title: string
  subtitle: string
  status: number
}

const Subscription: React.FC<SubProps> = ({ color, title, subtitle, status }) => {

  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;

  const { dispatch } = useContext(DataContext)
  const [loading, setLoading] = useState(false)

  const [counts, setCounts] = useState([])
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setLoading(true)
    axios
      .get(`/subscription/count-subscription-bycustomer/${status}/${user.customer_id}`)
      .then((resp) => {
        setCounts(resp.data.count)
        const delayDebounceFn = setTimeout(() => {
          setLoading(false)
        }, 500)
        return () => clearTimeout(delayDebounceFn)
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })
    //Refresh 5min per time
    const delayDebounceFn = setTimeout(() => {
      setRefresh(x => x + 1)
    }, 300000)
    return () => clearTimeout(delayDebounceFn)
  }, [dispatch, status, refresh, user])
  return (
    <div className={`d-flex align-items-center bg-light-${color} rounded p-5 mb-7 position-relative`}>
      <span className={`fw-bolder text-${color} fs-1 me-10`}>
        {loading ?
          <span className={`spinner-border text-${color}`}></span>
          :
          <span className={`fw-bolder mb-2 text-${color}`}>
            {counts}
          </span>
        }
      </span>

      <div className='flex-grow-1 me-2'>
        <Link to={`/customer/${user.customer_id}/${user.agent_id}/detail/subscription`} className={`fw-bolder text-gray-800 text-hover-${color} fs-6`}>
          {title}
        </Link>
        <span className='fw-bold d-block'>{subtitle}</span>
      </div>
    </div>
  )
}

const SubscriptionDash: React.FC<{ className: string }> = ({ className }) => {
  return (
    <div className='card card-xl-stretch'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Subscriptions</h3>
      </div>
      <div className='card-body py-0'>
        <Subscription
          color='primary'
          title='Active'
          subtitle='Subscription'
          status={1}
        />
        <Subscription
          color='danger'
          title='Close'
          subtitle='Subscription'
          status={3}
        />
      </div>
    </div>
  )
}

export { SubscriptionDash }


