import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../_metronic/helpers'
import { DataContext } from '../../../reducer/GlobalState'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'

type Props = {
  color: string
  title: string
  subtitle: string
  icon: string
  status?: number
  api: string
  className: string
  money?: boolean
  url: string
}

export const DashboardCount: React.FC<Props> = ({
  color,
  title,
  subtitle,
  icon,
  status,
  api,
  className,
  money,
  url
}) => {

  const user = useSelector<RootState>(({ auth }) => auth.user,shallowEqual) as UserModel;

  const baseColor = getCSSVariableValue(`--bs-${color}`)
  const lightColor = getCSSVariableValue(`--bs-light-${color}`)
  const { dispatch } = useContext(DataContext)
  const [loading, setLoading] = useState(false)

  const [counts, setCounts] = useState([])

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${api}/${status}/${user.customer_id}`)
      .then((resp) => {
        setCounts(resp.data.count)
        setLoading(false)
      })
      .catch((err) => {
        dispatch({ type: 'NOTIFY', payload: { error: err.message } })
      })

  }, [dispatch, status, api, user])

  return (
    <div className={`${className} mb-5`}>
      <div className={`card-header align-items-start border-0 mt-3`}>
        <h3 className='card-title align-items-start flex-column'>
          {loading ?
            <span className={`spinner-border text-${color}`}></span>
            :
            <span className={`fw-bolder mb-2 fs-1 text-${color}`}>
              {counts} {money && '$'}
            </span>
          }
          <span className='text-muted fw-bold fs-7'>{subtitle}</span>
        </h3>
      </div>

      <div className={`rounded-bottom position-relative pb-`}
        style={{
          backgroundImage: `linear-gradient(15deg, ${baseColor}, ${lightColor})`
        }}>
        <div className='p-3'>
          <KTSVG path={icon} className={`svg-icon-3x svg-icon-${color} position-absolute top-0 start-75`} />
          <Link to={url} className='fw-bolder text-white'>
            {title}
          </Link>
        </div>
      </div>

    </div>
  )
}
