import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../../../setup'
import { KTSVG } from '../../../../../_metronic/helpers'
import { UserModel } from '../../../../modules/auth/models/UserModel'

const TobeRenewModal: React.FC<{ showModal: any, setShowModal: any }> = ({ showModal, setShowModal }) => {

    const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    const handleModelClose = () => setShowModal(!showModal)

    const [isLoading, setIsLoading] = useState(false)
    const [toberenew, setToberenew] = useState([])

    useEffect(() => {
        setIsLoading(true)
        axios
            .get(`/subscription/list-subscription-toberenew-bycustomer/${user.customer_id}`)
            .then((resp) => {
                if (resp.data.status === 200) {
                    setIsLoading(false)
                    setToberenew(resp.data.data)
                }
            })
    }, [user.customer_id])

    const renderVender = () => {
        return toberenew.map((vender: any, idx: number) => (
            <tr key={idx}>
                <td>{idx + 1}</td>
                <td>
                    <Link to={`/customer/${vender.CustomerID}/${vender.AgentID}/detail/subscription/${vender.SubscriptionID}/detail`}>
                        {vender.SubscriptionID}
                    </Link>
                </td>
                <td>
                    <Link to={`/customer/${vender.CustomerID}/${vender.AgentID}/detail`}>
                        {vender.CustomerID}
                    </Link>
                </td>
                <td>{vender.SubscriptionName}</td>
                <td>{vender.TariffName}</td>
                <td>${vender.AdjRecurringFee}</td>
                <td>{vender.NextBillingDate}</td>
                <td>{vender.Days}</td>
            </tr>
        ))
    }

    return (
        <Modal show={showModal} scrollable={true} onHide={handleModelClose} size='xl' centered>
            <Modal.Header>
                <Modal.Title>To Be Renew</Modal.Title>
                <div className='btn btn-icon btn-sm btn-light-primary' onClick={handleModelClose}>
                    <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='table-responsive'>
                    <table id='kt_customers_table' className='table align-middle table-row-dashed fs-6'>
                        <thead>
                            <tr className='text-start text-gray-400 fw-bolder fs-7'>
                                <th className=''>No</th>
                                <th className=''>SID</th>
                                <th className=''>CID</th>
                                <th className='min-w-150px'>Subscription Name</th>
                                <th className='min-w-150px'>Tariff Name</th>
                                <th className='min-w-150px'>AdjRecurring Fee</th>
                                <th className='min-w-150px'>Next Biiling Date</th>
                                <th className=''>Days</th>
                            </tr>
                        </thead>
                        <tbody className='fw-bold text-gray-600'>
                            {isLoading ? (
                                <tr>
                                    <td colSpan={9}>
                                        <p className='text-center'>Loading...</p>
                                    </td>
                                </tr>
                            ) : (
                                toberenew instanceof Array && renderVender()
                            )}
                        </tbody>
                    </table>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default TobeRenewModal
