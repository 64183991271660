import {ACTIONS} from './Actions'

const reducers = (state, {type, payload, count}) => {
  switch (type) {
    case ACTIONS.IS_LOADING:
      return {
        ...state,
        is_loading: payload,
      }
    case ACTIONS.NOTIFY:
      return {
        ...state,
        notify: payload,
        count: count,
      }
    case ACTIONS.SET_MENUS:
      return {
        ...state,
        sidebarMenus: payload,
      }


    default:
      return state
  }
}

export default reducers
