import React, {createContext, useReducer, useEffect} from 'react'
import axios from 'axios'
import reducers from './Reducers'
import {shallowEqual, useSelector} from 'react-redux'
export const DataContext = createContext()

export function DataProvider({children}) {
  const initialState = {
    is_loading: true,
    notify: {},
    sidebarMenus: {},
  }
  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)
  const [state, dispatch] = useReducer(reducers, initialState)

  useEffect(() => {
    if (accessToken) {
      const menuFetch = async () => {
        await axios.get(`/menu-list`).then((resp) => {
          if (resp.data.status === 'success') {
            dispatch({
              type: 'SET_MENUS',
              payload: resp.data.data,
            })
          }
        })
      }

      menuFetch()
    }
  }, [accessToken])

  return <DataContext.Provider value={{state, dispatch}}>{children}</DataContext.Provider>
}
